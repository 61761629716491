import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { BaseForm, Control, ControlDto, EntityTypeFieldDto, getEnumOptions, ModuleKeywords } from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ControlsMappingService } from '@shared/services/mappings/framework/controls-mapping.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { ControlDataService } from '../../service/data/ControlDataService';

@Component({
  selector: 'app-control-item-form',
  templateUrl: './control-item-form.component.html',
  styleUrls: ['./control-item-form.component.scss'],
})
export class ControlItemFormComponent extends BaseForm<ControlDto> implements OnInit {
  respList = [EntityTypeFieldDto.CategoryEnum.Responsibility];
  categoryList = [
    EntityTypeFieldDto.CategoryEnum.Responsibility,
    EntityTypeFieldDto.CategoryEnum.Asset,
    EntityTypeFieldDto.CategoryEnum.Event,
    EntityTypeFieldDto.CategoryEnum.Objective,
    EntityTypeFieldDto.CategoryEnum.Process,
    EntityTypeFieldDto.CategoryEnum.Geography,
  ];

  listOfTypes = getEnumOptions(Control.ControlClassificationEnum);
  // listOfControlFrequencyTypes = getEnumOptions(
  //         Control.ControlFrequencyEnum
  //     );

  @Output() sendSubmit: EventEmitter<any> = new EventEmitter();

  // standardBodySectionFilter: FilterItem = { 'property': 'type', 'operation': 'EQUAL', 'value': standardBodySection.TypeEnum.ComplianceRequirements };

  constructor(
    public viewModeService: ViewModeService,
    private _dialogService: AppDialogService,
    private controlDataService: ControlDataService,
    private jWTTokenService: JWTTokenService,
    public mapperService: ControlsMappingService
  ) {
    super(viewModeService, ModuleKeywords.Control);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.getChangedFormValues(this.formatGetData()).updateItems;
  }
  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    if (ret?.standard?.code) ret.standard = ret?.standard?.code;
    if (ret?.controlGroup?.code) ret.controlGroup = ret?.controlGroup?.code;
    return ret;
  }
  setData(data: any) {
    let patchVal = { ...data };
    if (patchVal?.standard) patchVal.standard = { code: data?.standard };
    if (patchVal?.controlGroup) patchVal.controlGroup = { code: data?.controlGroup };
    this.data = data;
    this.formGroup.patchValue({ ...patchVal });

    // this.formGroup
    //     .get("controlGroup")
    //     .valueChanges.subscribe((objCode) => {
    //         if (objCode) {
    //             this.formGroup.get("standard").patchValue(objCode?.standard ? { code: objCode?.standard } : ((this.data?.controlGroup == objCode?.code && this.data?.standard) ? { code: this.data?.standard } : null));
    //             this.formGroup.get("standardSection").patchValue(objCode?.standardSection ? objCode?.standardSection : ((this.data?.controlGroup == objCode?.code && this.data?.standardSection) ? this.data?.standardSection : null));
    //             this.formGroup.get("standard").disable();
    //             this.formGroup.get("standardSection").disable();
    //             this.formGroup.updateValueAndValidity();
    //         } else {
    //             this.formGroup.get("standard").enable();
    //             this.formGroup.get("standardSection").enable();
    //             this.formGroup.updateValueAndValidity();
    //         }
    //     });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      controlClassification: new FormControl(null, Validators.required),
      controlFrequency: new FormControl(null, Validators.required),
      attestationRespondent: new FormControl(null, Validators.required),
      controlAttestationTemplate: new FormControl(null),
      controlEntity: new FormControl(null),
      description: new FormControl(null),
      controlGroup: new FormControl(null),
      // standard: new FormControl(null, Validators.required),
      // standardSection: new FormControl(null, Validators.required),
      controlObjectives: new FormControl(null),
      controlComplianceStatus: new FormControl({ value: null, disabled: true }),
      sourceOfControl: new FormControl(null),
      sourceReference: new FormControl(null),
      uniqueIdentifier: new FormControl(null),
    });
  }

  onSendForm() {
    this.sendSubmit.emit();
  }
  get canEditField() {
    return this.formGroup.controls.controlGroup.getRawValue() ? 'view' : this.fieldViewMode;
  }
}
